import React from "react";
import "./TermsBody.css";
import about_2 from "../../images/useful_images_svg/anurag.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const TermsBody = () => {
  return (
    <div className="terms">
      <div className="terms-header">
        {" "}
        <div className="overlay"></div>
        <div className="content">
          <h4>Terms And Conditions</h4>
        </div>
      </div>

      <div className="terms-content">
	  <p>Updated on and valid henceforth : 09-February-2024</p>
	  <p>Thank you for using Doctorspectra. These terms of use (“Terms”) govern your (hereinafter be referred to as “You”, “Your”, “User” as applicable) access and/or use of:</p>
	  <ol>
	  <li>https://www.doctorspectra.com/ and https://www.doctorspectra.in/ including any subdomains thereof (collectively, “Site”);</li>
	  <li>All related widgets, tools, applications, data, software, APIs, mobile, tablet and other smart device applications (collectively, “Application”); and</li>
	  <li>Services (as defined herein below).</li>
	  </ol>
	  <p>The Site and the Application together are hereinafter collectively referred to as the “Platform”, provided by Doctorspectra Technological Services Private Limited, a private limited company with its registered office at House no.10, Prestige Oasis, Rajanukunte, Dodaballapur road, Bangalore – 560064, Karnataka, operating under the brand name “Doctorspectra”(“Company”, “Doctorspectra”, “We”, “Us”, “Our” and terms of similar meaning).</p>
	  <p>PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE PLATFORM. THESE TERMS CONSTITUTE A BINDING, LEGAL AGREEMENT BETWEEN YOU AND THE COMPANY. IN ORDER TO USE THE PLATFORM AND/OR ACCESS THE SERVICES, YOU NEED TO AGREE TO THESE TERMS. IN THE EVENT YOU DO NOT AGREE WITH THESE TERMS IN THEIR ENTIRETY, YOU MAY NOT BE ABLE TO USE THE PLATFORM IN ITS ENTIRETY OR ACCESS THE SERVICES.</p>
	  <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
	  <p>These Terms are published in compliance of, and is governed by the provisions of Indian laws (as amended from time to time), including:</p>

	  <ol>
	  <li>The Indian Contract Act, 1872;</li>
	  <li>The (Indian) Information Technology Act, 2000 and the rules, regulations, guidelines and clarifications framed thereunder, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011, Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021; and</li>
	  <li>The (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011.</li>
	  </ol>
	  
	  <p>It is not mandatory to register to visit or access the Site. However, access to certain features/ services on the Platform is only available to registered users. When You use the Platform, you will be subject to the rules, guidelines, policies, terms, and conditions applicable to any service, and they shall be deemed to be incorporated into these Terms and shall be considered as part and parcel of these Terms. These Terms are subject to change at any time without notice. To make sure You are aware of any changes, please review these Terms periodically. Continued use of the Platform or Services after any such changes shall constitute Your consent to such changes.
	  </p>
	  <p>By impliedly or expressly accepting these Terms of Use, you also accept and agree to be bound by the Privacy Policy available on the Site, as amended from time to time (“Privacy Policy”).
	  </p>
	  <p>By using the Platform, you represent and warrant that:
	  </p>

	  <ol>
	  <li>You are at least 18 years old;
	  </li>
	  <li>You have the lawful authority and capacity to contract and be bound by these Terms;
	  </li>
	  <li>If You are accepting these Terms on behalf of a company, limited liability partnership or other legal entity, You have the authority to bind such entity to these Terms and, in such event, “You” and “Your” as used in these Terms shall refer to such entity;
	  </li>
	  <li>You will comply with all applicable laws and regulations.
	  </li>
	  </ol>

	  <h2>User Account</h2>
	  <ol>
	  <li>To register with Us, You will be required to open an account by completing the registration process (i.e. by providing Us with current, complete and accurate information as prompted by the applicable registration form). You also will choose a password and a user name (“User Account”).
	  </li>
	  <li>If You use the Platform, You shall be responsible for maintaining the confidentiality of your Display Name and Password and You shall be responsible for all activities that occur under your Display Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the Terms, We shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform and refuse to provide You with access to the Platform.
	  </li>
	  <li>Your mobile phone number and/or e-mail address is treated as Your primary identifier on the Platform. It is your responsibility to ensure that Your mobile phone number and your email address is up to date on the Platform at all times.
	  </li>
	  <li>You agree that We shall not be liable or responsible for the activities or consequences of use or misuse of any information that occurs under your User Account in cases, including, where You have failed to update Your revised mobile phone number and/or e-mail address on the Platform.
	  </li>
	  </ol>

	  <h2>Access and Use</h2>
	  <p>Doctorspectra grants You a limited, personal, non-exclusive, non-transferrable and non-sub licensable right to use the Platform, subject to the Terms and the following representations and warranties:
	  </p>
	  <ol>
	  <li>You may only access the Platform using authorized and lawful means;
	  </li>
	  <li>Doctorspectra shall not be liable for any failure or default to provide access to the Platform on account of any failure or delay by You to register with the Platform for such access;
	  </li>
	  <li>Any configuration or set up of the Devices for access to the Platform shall be Your sole “Device” means a device, usually electronic, that processes data according to a set of instructions, which may include workstations, personal computers, laptops, netbooks, personal digital assistants, tablets, and smartphones;
          </li>
	  <li>We collect, store, process and use Your information in accordance with Company’s Privacy Policy. By using the Platform and/ or by providing Your Personal Information (as defined in the Privacy Policy), You consent to the collection and use of the information You disclose to Us, in accordance with the Privacy Policy;
	  </li>
	  <li>You will not knowingly include or use any false or inaccurate information in the Personal Information;
	  </li>
	  <li>You will not take any action that interferes with, degrades or adversely affects the Company and/or the Platform;
	  </li>
	  <li>You will not use the Platform in a manner (i) that is prohibited by any law or regulation, or facilitates the violation of any law or regulation; or (ii) will disrupt a third parties’ similar use; (iii) violate or tamper with the security of the Platform;
	  </li>
	  <li>You will not use the Platform, or any portion thereof, to host, display, upload, modify, publish, transmit, store, update and/or share any information that:
          </li>
	  <ol>
	  <li>belongs to another person and to which the user does not have any right;
	  </li>
	  <li>is defamatory, obscene, pornographic, pedophilic, invasive of another’s privacy, including bodily privacy, insulting or harassing on the basis of gender, libellous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force;
	  </li>
	  <li>is harmful to children;
	  </li>
	  <li>infringes any patent, trademark, copyright or other proprietary rights;
	  </li>
	  <li>deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;
	  </li>
	  <li>impersonates another person;
	  </li>
	  <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation;
	  </li>
	  <li>is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.
	  </li>
	  </ol>
	  <li>The Platform may not be used to host, store or publish any information that has been brough to the knowledge of the Company, by way of an order by a Court of competent jurisdiction or by notification by an appropriate governmental authority, to be prohibited under any law for the time being in force in relation to the interest of the sovereignty and integrity of India;   security of the State; friendly relations with foreign States; public order; decency or morality; in relation to contempt of court; defamation;   incitement to an offence relating to the above, or any information which is prohibited under any law for the time being in force;
	  </li>
	  <li>You will ensure that the Platform is not used to upload, post, transmit, or otherwise make available any content that contains a virus or any other form of malicious code or data that is likely or intended to have an adverse impact on, or provide unauthorized access to, the Platform or any other software, hardware, services or data;
	  </li>
	  <li>You will not attempt to gain unauthorised access to any accounts, computer systems or networks connected to the Platform, through hacking, or any other means, or obtain or attempt to obtain any materials or information through any means not intentionally made available to You;
	  </li>
	  <li>You will not copy, modify, adapt, translate, or reverse engineer any portion of the Platform, its content or materials and/or the services. You will not use the Platform to develop, or to assist anyone in developing a competitive Platform, service or for other competitive purposes;
	  </li>
	  <li>You shall be solely responsible for (i) procuring and maintaining Your network connections and telecommunications links from Your systems to the Company’s data centres and (ii) all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to Your network connections or telecommunications links or caused by the internet;
	  </li>
	  <li>You acknowledge that from time to time, the Company may apply Upgrades (hereinafter defined) to the Platform, and that such Upgrades may result in changes the appearance and/or functionality of Platform. You may be required to install certain Upgrades or updates to the software in order to continue to access or use the Platform or portions thereof. “Upgrades” means new versions of, and updates to, Platform whether for the purpose of fixing an error, bug or other issue in the Platform or enhancing the functionality of Platform.
	  </li>
	  </ol>
      
	  <p>All communications transmitted through the Platform, including without limitation the Company’s messaging Platform shall be monitored for quality assurance, training and other purposes. By accepting these Terms, You consent to any such monitoring. The Company retains the right to terminate Your right to use the Platform in the event of non-compliance with applicable laws, or breach of these terms.
	  </p>

	  <h2>Services on the Platform</h2>
	  <p>Everything We offer on the Platform are collectively referred to in these Terms as the “Services”.
	  </p>
	  <ol>
	  <li>Further, We may from time to time add new services to the Services, substitute a new service for one of the existing Services, or discontinue or suspend one of the existing Services. Any such amendments to the Services will be subject to these Terms as well as any additional terms of use that We may release for those specific Services. You agree that the Company will not be liable to You or any third party for any suspension or discontinuation of any of the Services.
	  </li>
	  </ol>
	  <p>We reserve the right to cancel any of the Services in the event You breach of any of these Terms.
	  </p>

	  <h2>Payment Schedule and Mechanism</h2>
	  <ol>
	  <li>The Company reserves the right, at its sole discretion, to charge fees for any new/modified services that the Company may include in the Services in future. Changes are effective after the Company has provided You with 5 (Five) days’ notice by posting the changes on the Application.</li>
	  <li>The User agrees and acknowledges that the Company may use third-party payment processors (“Payment Processors”) for any such payments made on the Application. The processing of all payments will be subject to the terms, conditions and privacy policies of the Payment Processors in addition to these Terms. The User further agrees and acknowledges that neither the Company nor any of its directors, shareholders or other representatives shall be liable to the User under any circumstances for any direct, indirect, punitive, incidental, special or consequential damages that result from or arise out of use of the Payment Processors.</li>
	  </ol>

	  <h2>Geographic Limitation</h2>
	  <p>You may access the services or other features on the Application only in geographic locations where the Company offers the same on account of the applicable law or any order, rule imposed by a concerned statutory authority. The Company may use technologies to verify Your geographic location.</p>

	  <h2>Links to other Sites</h2>
	  <p>The Platform may contains links to other websites/applications controlled by parties other than the Company. The Company is not responsible for and does not endorse the contents or use of these websites/applications.
	  </p>

	  <h2>Feedback</h2>
	  <ol>
	  <li>As a participant, You agree to use careful, prudent, and good judgment when leaving feedback for other users of the Platform. In the event the feedback violates these Terms, is inappropriate or violates propriety or privacy of another user, the Company, in its sole discretion, may delete Your feedback or any of your postings; and/or report to law enforcement authorities, including but not limited to the Indian Computer Emergency Response Team, any actions that may be illegal, and any reports it receives of such conduct. When legally required or at the Company’s discretion, the Company will cooperate with law enforcement agencies in any investigation of alleged illegal activity on the Platform.</li>
	  <li>Reporting inappropriate use of feedback: You may contact the Company regarding any inappropriate use of feedback via-email at  contactus@doctorspectra.com</li>
	  <li>The Company does not and cannot review every posting made on the Platform. These Terms do not require the Company to monitor, police or remove any postings or other information submitted by You or any other user and the Company shall not be responsible for any ensuing liability.</li>
	  </ol>

	  <h2>Intellectual Property Rights:</h2>
	  <p>You hereby acknowledge that any and all intellectual property rights (including but not limited to all trademark, copyright, patent, service marks, etc.) and other proprietary rights in and in relation to the Platform including without limitation any derivatives, improvements or modifications which ownership is directly attributable to the Company (expressly excluding any personal information, intellectual property which belongs to a third party) and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by You or any other party relating to the Platform or the services shall vest wholly completely and fully with the Company throughout the territory of the world and You shall have no right or claim to such intellectual property in any manner whatsoever.</p>

	  <h2>Disclaimer of Warranties and Liabilities</h2>
	  <ol>
	  <li>You understand and agree that the Company provides the Platform on an ‘as-is’ and ‘as available’ basis. You agree that use of the Platform is at Your risk. All warranties including without limitation, the implied warranties of merchantability, fitness for a particular purpose, for title and non-infringement are disclaimed and excluded.</li>
	  <li>No representations, warranties or guarantees whatsoever are made by the Company as to the (1) accuracy, adequacy, reliability, completeness, suitability or applicability of the information to a particular situation; (2) that the sensors and/or features of the Platform will be uninterrupted, timely, secure, or error-free; (3) the quality of any Services, content, information, or other material on the Platform will meet Your expectations or requirements; (4) any errors in the Platform will be corrected; (5) warranties against infringement of any third party intellectual property or proprietary rights; or (6) other warranties relating to performance, non-performance, or other acts or omissions of the Company, its officers, directors, employees, affiliates, agents, licensors, or suppliers.</li>
	  <li>The Company does not make any representation or warranty regarding the reliability, accuracy, completeness, correctness, or usefulness of third party content, and disclaims all liabilities arising from or related to third party content.</li>
	  <li>The Company does not warrant that any of the software used and or licensed in connection with the Platform will be compatible with other third party software or devices nor does it warrant that operation of the Platform and the associated software will not damage or disrupt other software or hardware.</li>
	  <li>Notwithstanding anything contained elsewhere in these Terms, in no event shall the Company be liable to You or anyone claiming under You for any costs or loss incurred or suffered by You or anyone claiming under You, including but not limited to any special, exemplary, consequential, incidental, punitive or indirect damages on any theory of liability, whether in contract, tort (including without limitation negligence), strict liability or otherwise. In no event or circumstance will the Company be under any liability to make good any loss whether by way of any monetary payment or otherwise.</li>
	  <li>The Company or anyone else involved in administering, distributing or providing the Platform further explicitly disclaims any and all liability for any mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses computer viruses or other harmful, disabling computer code, computer instructions, circuitry or other technological means whose purpose is to disrupt, damage or interfere with any computer and communications facilities or equipment (“Harmful Code“) that may be transferred to Your Devices when accessing the Platform. By way of clarification, Harmful Code shall include, without limitation, any code containing viruses, Trojan horses, worms or like destructive code or code that was intentionally written to self-replicate. You are advised to obtain and use appropriate anti-virus and security software and to take all other appropriate measures to safeguard the integrity of Your Devices.</li>
	  </ol>
	  
	  <h2>Indemnification</h2>
	  <p>By accepting these Terms and using the Platform, You agree that You shall defend, indemnify and hold the Company, its directors, shareholders, officers and other representatives harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys’ fees and costs) arising out of or in connection with: (1) Your access to the Platform, (2) Your use of the services/features of the sensors and/or Platform, or (3) Your violation or breach of these Terms or any applicable law or regulation; (4) Your violation of any rights of any third party including intellectual property or other right of any person or entity; or (5) any and all third-party claims based upon the content of any communications transmitted by You.</p>

	  <h2>Termination</h2>
	  <p>These Terms will continue to apply until terminated by either You or Us as set forth below.</p>
      	  <ol>
	  <li>Termination by You: If You wish not to be bound by these Terms, You may terminate your relationship with Us by unsubscribing to the Platform.</li>
	  <li>Termination by Us: The Company may at its discretion and at any time with or without notice, terminate or suspend the Terms, with or without cause if: (i) You breach any of the provisions of the Terms, the Privacy Policy or any other terms, conditions, or policies that may be applicable to You; (ii) Company is required to do so in accordance with law; or (iii) Company has elected to discontinue, with or without reason, access to the Platform (or any part thereof) either in general or specifically to You.</li>
	  </ol>
	  <p>The Company shall not be liable to You or any third party for any such termination.</p>
      
	  <h2>Consequences of Termination</h2>
      	  <ol>
	  <li>	Once Your Account has been terminated, or any information submitted by you is deemed to be in contravention of the any of the terms herein, your information will be retained for a period of one hundred and eighty days subsequent to such termination, as required by law. Post this period, any and all content will be irretrievably deleted by Us, except to the extent that We are further obliged to maintain or permitted to retain in accordance with law.</li>
	  <li>The licences granted to You in terms of these Terms shall stand terminated, effective immediately.</li>
	  <li>The Company, in its sole discretion, may initiate appropriate legal proceedings against You, if necessary in the event the termination is on account of Your breach of these Terms.</li>
	  <li>	Termination shall not affect Your liability or obligations arising prior to such termination and for the sake of avoidance of any doubt, it is hereby clarified that deletion, uninstallation of the Platform and/or discontinuation of our Services, shall not discharge you from the responsibility, obligation and liability to repay outstanding amount(s), if any, in accordance with these Terms.</li>
	  </ol>

	  <h2>General</h2>
      	  <ol>
	  <li>To the extent that anything in or associated with the Platform is in conflict or inconsistent with these Terms, these Terms shall take precedence and prevail. Our failure to enforce any provision of these Terms shall not be deemed a waiver of such provision nor of the right to enforce such provision. Our rights under these Terms shall survive any discontinuance of the access or use of the Platform.</li>
	  <li>If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law.</li>
	  <li>Any notices to You from the Company regarding the Platform or these Terms will be posted on the Platform or sent by e-mail or regular mail.</li>
	  <li>Governing Law: These Terms shall be governed and construed under the laws of India and the courts of Haryana shall have exclusive jurisdiction.</li>
	  </ol>
      

	  <h2>Doctorspectra Officer</h2>
	  <p>We encourage You to contact Us, the Doctorspectra Officer vide the below indicated contact details if You have any questions concerning our Terms or the Platform.</p>
	  <p>Details of the Doctorspectra Officer:</p>
      	  <ul>
	  <li>Name: Rati Gugnani<br/>
	  Designation: CEO<br/>
	  Email: rati@doctorspectra.com<br/>
	  </li>
	  </ul>

      </div>
    </div>
  );
};

export default TermsBody;
